import dayjs from "dayjs";
import { get } from "lodash";
import { useState } from "react";
import { DateTimePicker as DateTime } from "@mui/x-date-pickers";

import TextField from "./TextField";

export default function DateTimePicker({
  required = false,
  formikProps,
  editState = true,
  name,
  label,
  ...props
}) {
  const valueFromFormik = get(formikProps.values, name);

  const initialValue = valueFromFormik
    ? dayjs(new Date(valueFromFormik).toUTCString())
    : null;

  const [value, setValue] = useState(() => initialValue || null);

  const handleChange = (newValue) => {
    setValue(newValue);

    formikProps.setValues((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const hasError =
    formikProps.touched[name] && Boolean(formikProps.errors[name]);

  return (
    <DateTime
      name={name}
      label={label}
      formikProps={formikProps}
      disabled={!editState}
      value={value || initialValue}
      {...props}
      onChange={(newValue) => {
        handleChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          required={required}
          disabled={!editState}
          label={label}
          name={name}
          formikProps={formikProps}
          error={hasError}
          helperText={
            hasError ? (
              <span style={{ color: "red" }}>{formikProps.errors[name]}</span>
            ) : null
          }
          {...params}
        />
      )}
    />
  );
}
