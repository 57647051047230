export const eventBokingObject = {
  event: "",
  eventAmount: 0,
  memberCount: 0,
  paymentStatue: "",
  vehicle: [],
  visitorData: [],
  badge: [],
};

export function objectFromLocationData(data, eventObject, badgeOptions) {
  let value = { visitorData: [{}] };

  if (Object.keys(data || {})?.includes("userData")) {
    data?.userData.map((item, index) => {
      value.visitorData[index][`name`] =
        item?.visitor_info?.first_name || item?.visitor_info?.last_name;

      value.visitorData[index][`phone_number`] =
        item?.visitor_info?.phone_number ||
        sessionStorage.getItem("mobileNumber");

      value.visitorData[index][`email_id`] =
        item?.visitor_info?.email_id || sessionStorage?.getItem("email");

      value.event = item?.event_id;

      value.eventAmount = eventObject?.ticket_cost || 0;

      value.visitorData[index][`gender`] = item?.visitor_info?.gender;

      value.paymentStatue = item?.payment_status;
      value.vehicle = [];
      value.badge = item.badges;
    });
  }

  return value;
}

export function objectFromData(data, id, badge, vehicleDetails) {
  let formData = data.visitorData.map((item, index) => {
    return {
      ...item,
      booking_date: Date.now() / 1000,
      ...(index > 0 && { is_dependent: true }),
      visitor_id: id,
      phone_number: "91" + item.phone_number,
    };
  });

  let totaBadgeValue =
    data.badge.reduce((sum, current, index) => {
      return (
        parseInt(current?.badge_count) * parseInt(current?.badge_id_amount) +
        sum
      );
    }, 0) +
    data.vehicle?.reduce((sum, current, index) => {
      return (
        (parseInt(
          vehicleDetails.filter((ite, index) => {
            return ite.vehicle_type == current?.vehicle_type;
          })[0]?.amount
        ) || 0) + sum
      );
    }, 0);

  return {
    event_id: data.event,
    total_price: data.eventAmount * data.visitorData.length + totaBadgeValue,
    reference_visitor_id: id,
    total_bookings: data.badge?.reduce((sum, current, index) => {
      return parseInt(current?.badge_count) + sum;
    }, 0),
    badges: data.badge,
    visitors: formData,
  };
}
