import * as Yup from "yup";
import { Formik } from "formik";
import { QrReader } from "react-qr-reader";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import { useAxios } from "../../hooks";
import { Label, OuterBox, TextField } from "../../components";
import { setDisplayName } from "../../redux/slices/memberSlice";

const ResendNotification = () => {
  const axios = useAxios({
    baseURL: "mox_api_2",
  });

  const dispatch = useDispatch();

  const { authToken, displayName, eventID } = useSelector(
    (state) => state.memberDetails
  );

  const [userStep, setUserStep] = useState("");
  const [status, setStatus] = useState(undefined);
  const [agentName, setAgentName] = useState(displayName || "");
  const [guardDetails, setGuardDetails] = useState({
    name: displayName || "",
    email: "",
    // QRCode: "00",
    mobile_no: "",
    bookingID: "",
  });

  useEffect(() => {
    if (Boolean(displayName)) {
      setUserStep("QRcode");
    }
  }, []);

  const StatusDisplay = ({ status, message, iconColor, onClick }) => (
    <Grid
      container
      display="flex"
      sx={{ flexDirection: "column" }}
      alignItems="center"
      justifyContent="center"
    >
      <CheckCircleOutlineRoundedIcon
        sx={{
          fontSize: "80px",
          lineHeight: "21px",
          textAlign: "center",
          textTransform: "uppercase",
          color: iconColor,
          marginBottom: "30px",
        }}
      />
      <Typography
        variant="h1"
        component="h2"
        sx={{
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "21px",
          textAlign: "center",
          textTransform: "uppercase",
          color: iconColor,
        }}
      >
        {message}
      </Typography>
      <Button
        variant="outlined"
        sx={{
          marginTop: "30px",
          color: iconColor,
          border: `1px solid ${iconColor}`,
        }}
        onClick={onClick}
      >
        Next
      </Button>
    </Grid>
  );

  return (
    <OuterBox>
      {
        <Formik
          initialValues={guardDetails}
          validationSchema={validationSchema}
          onSubmit={(formData, { setSubmitting }) => {
            setSubmitting(true);

            let url = "/visitor/resend-notification";

            axios({
              url: url,
              method: "POST",
              data: {
                email: formData.email,
                agent_name: formData.name,
                booking_id: formData.bookingID,
                mobile_number: formData.mobile_no,
                event_id: eventID || sessionStorage.getItem("eventID"),
              },
              disableRedirect: true,
            }).then((response) => {
              if (response.status) {
                setStatus(response.status);
              } else {
                setStatus(response.status);
              }
              setSubmitting(false);
            });
          }}
        >
          {(formikProps) => {
            const handleClick = () => {
              setUserStep("QRcode");
              setStatus(undefined);
              formikProps.setValues((prev) => ({
                name: agentName,
              }));
            };

            return (
              <Fragment>
                {agentName && status === undefined && userStep !== "" && (
                  <Grid container justifyContent={"end"}>
                    <Typography alignContent={"center"}>
                      Welcome
                      <span style={{ fontWeight: "bold" }}>{agentName}</span>
                    </Typography>

                    <Tooltip title="Edit Name">
                      <EditRoundedIcon
                        sx={{
                          fontSize: "30px",
                          borderRadius: "15px",
                          padding: "5px",
                          "&:hover": { background: "rgb(108 108 108 / 87%)" },
                        }}
                        onClick={() => {
                          function stopCamera() {
                            const stream =
                              document.querySelector("video")?.srcObject;
                            if (stream) {
                              const tracks = stream?.getTracks();

                              tracks.forEach((track) => {
                                track.stop();
                              });

                              document.querySelector("video").srcObject = null;
                            }
                          }

                          stopCamera();
                          setUserStep("");
                        }}
                      />
                    </Tooltip>
                  </Grid>
                )}

                {!status && (
                  <Fragment>
                    {userStep === "" && (
                      <Grid container>
                        <Grid item xs={4} alignSelf={"center"}>
                          <Label
                            formikProps={formikProps}
                            title={"Enter Your Name"}
                          />
                        </Grid>

                        <Grid item xs={7}>
                          <TextField name="name" formikProps={formikProps} />
                        </Grid>

                        <Grid item xs={1}>
                          <Button
                            variant="contained"
                            sx={{ height: "100%" }}
                            disabled={displayName ? false : !formikProps.dirty}
                            onClick={() => {
                              setAgentName(formikProps.values.name);
                              dispatch(setDisplayName(formikProps.values.name));
                              setUserStep("QRcode");
                            }}
                          >
                            <ArrowForwardIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    )}

                    {userStep === "QRcode" && (
                      <Grid container>
                        {formikProps.values.name &&
                          !formikProps.values.QRCode &&
                          false && (
                            <Fragment>
                              <Grid item container justifyContent={"center"}>
                                <Typography
                                  variant="h5"
                                  sx={{ fontWeight: 600 }}
                                >
                                  Scan QR Code{" "}
                                </Typography>
                              </Grid>

                              <Grid item container justifyContent={"center"}>
                                <Grid item sx={{ width: "50vh" }}>
                                  <QrReader
                                    onResult={(result, error) => {
                                      if (!!result) {
                                        let [eventID, bookingID] =
                                          result?.text.split("_");

                                        formikProps.setValues((prevVal) => ({
                                          ...prevVal,
                                          QRCode: bookingID,
                                        }));
                                      }

                                      if (!!error) {
                                        console.info(error);
                                      }
                                    }}
                                    scanDelay={500}
                                    constraints={{
                                      facingMode: "environment",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Fragment>
                          )}

                        {status === undefined && (
                          <Grid item container spacing={3} mt={3}>
                            <Grid item xs={12} display={"flex"}>
                              <Label
                                formikProps={formikProps}
                                title={"Enter Booking ID"}
                              />
                              <Grid item container justifyContent={"end"}>
                                <TextField
                                  name="bookingID"
                                  formikProps={formikProps}
                                />
                                <Typography color={"red"}>
                                  {formikProps.errors.bookingID}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} display={"flex"}>
                              <Label
                                formikProps={formikProps}
                                title={"Enter Email"}
                              />

                              <TextField
                                name="email"
                                formikProps={formikProps}
                              />
                            </Grid>

                            <Grid item xs={12} display={"flex"}>
                              <Label
                                formikProps={formikProps}
                                title={"Enter Mobile No"}
                              />

                              <Grid item container justifyContent={"end"}>
                                <TextField
                                  name="mobile_no"
                                  formikProps={formikProps}
                                />

                                <Typography color={"red"}>
                                  {formikProps.errors.mobile_no}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              display={"flex"}
                              justifyContent={"end"}
                            >
                              <Button
                                variant="contained"
                                sx={{ height: "100%" }}
                                disabled={
                                  !formikProps.dirty || !formikProps.isValid
                                }
                                loading={formikProps.isSubmitting}
                                onClick={formikProps.handleSubmit}
                              >
                                <ArrowForwardIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Fragment>
                )}

                {status !== undefined && status && (
                  <StatusDisplay
                    status={status}
                    message="Notification Sent"
                    iconColor="rgb(41 128 31)"
                    onClick={handleClick}
                  />
                )}
                {status !== undefined && !status && (
                  <StatusDisplay
                    status={status}
                    message="No Booking Found"
                    iconColor="red"
                    onClick={handleClick}
                  />
                )}

                {/* {status !== undefined && status && (
                  <Grid
                    container
                    display={"flex"}
                    sx={{ flexDirection: "column" }}
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <CheckCircleOutlineRoundedIcon
                      sx={{
                        fontSize: "80px",
                        lineHeight: "21px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "rgb(41 128 31)",
                        marginBottom: "30px",
                      }}
                    />
                    <Typography
                      variant="h1"
                      component="h2"
                      sx={{
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "21px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "rgb(41 128 31)",
                      }}
                    >
                      Notification Send
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{ marginTop: "30px" }}
                      onClick={() => {
                        setUserStep("QRcode");
                        setStatus(undefined);
                        formikProps.setValues((prev) => ({
                          name: agentName,
                        }));
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                )}

                {status !== undefined && !status && (
                  <Grid
                    container
                    display={"flex"}
                    sx={{ flexDirection: "column" }}
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <CheckCircleOutlineRoundedIcon
                      sx={{
                        fontSize: "80px",
                        lineHeight: "21px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "red",
                        marginBottom: "30px",
                      }}
                    />
                    <Typography
                      variant="h1"
                      component="h2"
                      sx={{
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "21px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "red",
                      }}
                    >
                      No Booking Found
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{
                        marginTop: "30px",
                        color: "red",
                        border: "1px solid red",
                      }}
                      onClick={() => {
                        setUserStep("QRcode");
                        setStatus(undefined);
                        formikProps.setValues((prev) => ({
                          name: agentName,
                        }));
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                )} */}
              </Fragment>
            );
          }}
        </Formik>
      }
    </OuterBox>
  );
};

const validationSchema = Yup.object().shape({
  email: Yup.string(),
  bookingID: Yup.string().required("Booking ID is required"),
  mobile_no: Yup.string().when("email", {
    is: (val) => !val,
    then: Yup.string()
      .required("Mobile Number is required")
      .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits"),
    otherwise: Yup.string().matches(
      /^[0-9]{10}$/,
      "Mobile Number must be exactly 10 digits"
    ),
  }),
});

export default ResendNotification;
