import { Grid } from "@mui/material";
import { AiOutlineDelete } from "react-icons/ai";
import React, { Fragment, useEffect, useState } from "react";

import {
  CommonTextField,
  SelectField,
  TextField,
} from "../../../../components";
import { useAxios, useComponent } from "../../../../hooks";
import { useSelector } from "react-redux";

function Form1({ formik, memberCount, setMemberCount }) {
  const axios = useAxios();
  const { loader } = useComponent();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);

  useEffect(() => {
    loader.start();
    axios({
      url: "event/booking/states",
      method: "GET",
    })
      .then((response) => {
        if (response.status) {
          setStateOptions(response.data);
        }
        loader.apiComplete();
      })
      .catch((error) => {
        console.error("Error in fetching states list", error);
      });
  }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[A-Za-z\s]*$/;

    if (regex.test(inputValue)) {
      formik.handleChange(e);
    }
  };

  // Function to fetch districts based on the selected state
  const fetchDistricts = async (selectedState) => {
    try {
      const response = await axios({
        url: `event/booking/districts?state_id=${selectedState}`,
        method: "GET",
      });
      if (response.status) {
        setDistrictOptions(response.data);
      }
    } catch (error) {
      console.error("Error in fetching district list", error);
    }
  };

  // Function to handle state selection change
  const handleStateChange = (event, index) => {
    setDistrictOptions([]);
    const selectedState = event.target.value;
    formik.setFieldValue(`state${index}`, selectedState);
    formik.setFieldValue(`district${index}`, ""); // Reset selected district when state changes
    fetchDistricts(selectedState);
  };

  function handleRemoveMember() {
    setMemberCount(memberCount - 1);
    formik.setFieldValue("memberCount", memberCount - 1);
  }

  return (
    <Fragment>
      <Grid container>
        {Array(memberCount)
          .fill("0")
          .map((item, index) => {
            return (
              <Grid
                container
                spacing={2}
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "rgb(232, 196, 144) 3px 3px 5px -2px",
                  padding: "15px 15px 20px 5px !important",
                  borderRadius: "12.5px",

                  border: "1px solid rgb(232, 222, 207)",
                  position: "relative",
                  marginTop: index !== 0 && "25px",
                }}
              >
                {index !== 0 && (
                  <Grid
                    item
                    container
                    sx={{
                      position: "absolute",
                      top: "-25px",
                      justifyContent: "end",
                    }}
                  >
                    <AiOutlineDelete
                      style={{
                        color: "white",
                        padding: "3px",
                        fontSize: "35px",
                        backgroundColor: "#ff9191",
                        backgroundImage:
                          "linear-gradient(164deg, #ff9191 0%, #FF2525 74%)",
                        borderRadius: "5px",
                        boxShadow: "1px 2px 4px 1px #bbbaba",
                      }}
                      onClick={handleRemoveMember}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    name={`name${index}`}
                    label={"Name"}
                    required
                    disabled={visitorData.firstName}
                    formikProps={formik}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name={`mobile${index}`}
                    label={"Mobile No"}
                    formikProps={formik}
                    disabled={index === 0}
                  />
                </Grid>

                <SelectField
                  label="Select Your Gender"
                  name={`gender${index}`}
                  value={formik.values[`gender${index}`]}
                  options={[
                    { id: "Male", name: "Male" },
                    { id: "Female", name: "Female" },
                  ]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.district && formik.errors.district}
                  required
                  disabled={formik.values.paymentStatue === "Pending"}
                />

                <CommonTextField
                  label="Enter Your Email"
                  placeholder="Enter Your Email"
                  name={`email${index}`}
                  type="email"
                  value={formik.values[`email${index}`]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[`email${index}`] &&
                    Boolean(formik.errors[`email${index}`])
                  }
                  required
                />

                <SelectField
                  label="Choose Your State"
                  name={`state${index}`}
                  value={formik.values[`state${index}`]}
                  options={stateOptions.map((state) => ({
                    id: state.state_id,
                    name: state.state_name,
                  }))}
                  onChange={(e) => handleStateChange(e, index)}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[`state${index}`] &&
                    Boolean(formik.errors[`state${index}`])
                  }
                />

                <SelectField
                  label="Choose Your District"
                  name={`district${index}`}
                  value={formik.values[`district${index}`]}
                  options={districtOptions.map((district) => ({
                    id: district.district_id,
                    name: district.district_name,
                  }))}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[`district${index}`] &&
                    Boolean(formik.errors[`district${index}`])
                  }
                  disabled={districtOptions.length === 0}
                />

                <CommonTextField
                  label="Enter Your organization's Name (Optional)"
                  placeholder="Enter Your organization's Name (Optional)"
                  name={`organization${index}`}
                  value={formik.values[`organization${index}`]}
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[`organization${index}`] &&
                    Boolean(formik.errors[`organization${index}`])
                  }
                />
                <CommonTextField
                  label="Enter Your Designation (Optional)"
                  placeholder="Enter Your Designation (Optional)"
                  name={`designation${index}`}
                  value={formik.values[`designation${index}`]}
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[`designation${index}`] &&
                    Boolean(formik.errors[`designation${index}`])
                  }
                />
              </Grid>
            );
          })}
      </Grid>
    </Fragment>
  );
}

export default Form1;
