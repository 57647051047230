import styled from "styled-components";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useAxios from "./../../hooks/useAxios";
import EventSlider from "./EventSlider";
import { TiTicket } from "react-icons/ti";
import { RiGovernmentLine } from "react-icons/ri";
import { MdSportsHandball } from "react-icons/md";
import { BiSolidHotel } from "react-icons/bi";
import Events from "./assets/Events.svg";
import Sports from "./assets/Sports.svg";
import Ports from "./assets/Ports.svg";
import Hotels from "./assets/Hotels.svg";
import College from "./assets/College.svg";
import Corporate from "./assets/Corporate Events.svg";

import pages from "../../constants/pages";

const OuterBox = styled(Box)`
  display: flex;
  justify-content: center;
  // padding: 20px;
  background: linear-gradient(to right, #f7fbfc, #e2f4fc);
  min-height: 100vh;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  max-width: 1200px;
  // padding: 30px;
  // border-radius: 20px;
  background: #ffffff;
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const CategoryItem = styled(Grid)`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  text-align: center;
  // border: 2px solid #e0f2f8;
  border-radius: 12px;
  transition: all 0.1s ease;
  background: white;
  // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  // margin: 0px auto;
  padding: 0px 15px;

  box-shadow: 0px 2px 3px 1px #00000014;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }

  @media screen and (max-width: 768px) {
    width: 150px;
    height: 150px;
  }

  @media screen and (max-width: 480px) {
    width: 45%;
    height: 67px;
  }
`;

const CategoryGrid = styled(Grid)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 25px;
  // padding: 0px 35px;
`;

const CategoryIcon = styled.img`
  font-size: 35px;
  color: ${(props) => props.color || "#0073e6"};
  // margin-bottom: 10px;
`;

const HomeComponent = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const { visitorData } = useSelector((state) => state.memberDetails);
  const [bannerList, setBannerList] = useState([]);
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    axios({ url: "/admin/profile/get-event-banner" }).then((response) => {
      if (response.status) {
        setBannerList(response.data);
      }
    });

    axios({ url: "/event/booking/get-faq" }).then((response) => {
      if (response.status) {
        setFaqList(response.data);
      }
    });
  }, []);

  const categories = [
    {
      name: "Event",
      icon: Events,
      // color: "#edbc00",
      route: pages.eventListPage.route,
    },
    {
      name: "Govt",
      icon: Corporate,
      // color: "#0073e6",
      route: visitorData.ID
        ? pages.AppointmentBooking.route
        : `${pages.appointment.route}bookappointment`,
    },
    {
      name: "Sports",
      icon: Sports,
      // color: "#28a745",
      route: pages.eventListPage.route,
    },
    {
      name: "Hotels",
      icon: Hotels,
      // color: "#dc3545",
      route: pages.HotelBookingPage.route,
    },
  ];

  return (
    <Fragment>
      <OuterBox>
        <OuterPaper>
          <EventSlider bannerList={bannerList} backgroundColor="#FFF" />
          <Grid item container padding={2.5}>
            <Typography
              variant="h4"
              fontWeight="900"
              align="start"
              gutterBottom
            >
              Browse by Category
            </Typography>
            <CategoryGrid>
              {categories.map((category, index) => (
                <CategoryItem
                  key={index}
                  onClick={() => navigate(category.route)}
                >
                  <CategoryIcon src={category.icon}></CategoryIcon>
                  <Typography
                    variant="h6"
                    fontWeight="400"
                    sx={{ margin: "0px 10px" }}
                  >
                    {category.name}
                  </Typography>
                </CategoryItem>
              ))}
            </CategoryGrid>
          </Grid>

          <Grid item container padding={2.5}>
            <Typography
              variant="h4"
              fontWeight="900"
              align="start"
              gutterBottom
            >
              FAQ
            </Typography>

            <Box component="div" class="faq">
              {faqList.map((item, index) => {
                return (
                  <Fragment>
                    <input id="faq-a" type="checkbox"></input>

                    <label
                      for="faq-a"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div>
                        <p class="faq-heading">
                          {`Q${index + 1} .`} {item.question}
                        </p>
                        {/* <Box component="div" class="faq-arrow"></Box> */}
                      </div>

                      <p class="faq-text">{item.answer}</p>
                    </label>
                  </Fragment>
                );
              })}
            </Box>
          </Grid>
        </OuterPaper>
      </OuterBox>
    </Fragment>
  );
};

export default HomeComponent;
