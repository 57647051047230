import { Grid } from "@mui/material";
import { Fragment } from "react";
import { TiTicket } from "react-icons/ti";

export default function SummaryTable({
  formikProps,
  eventObject,
  vehicleDetails,
  badgeOptions,
}) {
  let totaBadgeValue =
    (formikProps.values.badge?.reduce((sum, current, index) => {
      return (
        parseInt(current?.badge_count) * parseInt(current?.badge_id_amount) +
        sum
      );
    }, 0) || 0) +
    (formikProps.values.vehicle?.reduce((sum, current, index) => {
      return (
        (parseInt(
          vehicleDetails.filter((ite, index) => {
            return ite.vehicle_type == current?.vehicle_type;
          })[0]?.amount
        ) || 0) + sum
      );
    }, 0) || 0);

  return (
    <Grid item container>
      <table
        style={{
          width: "100%",
          border: "1px solid #0000001A",
          padding: "10px",
          borderRadius: "5px",
          position: "relative",
          // boxShadow: "3px 3px 5px -2px rgb(232 196 144)",
        }}
      >
        <span
          class="__circle-left"
          style={{
            display: "inline-block",
            position: "absolute",
            top: "45%",
            zIndex: "1",
            width: "22px",
            height: "22px",
            background: "#ffffff",
            borderRadius: "50%",
            left: "-11px",
            boxShadow: "inset -2px 0 0 #0000001A",
          }}
        ></span>
        <span
          class="__circle-right"
          style={{
            display: "inline-block",
            position: "absolute",
            top: "40%",
            zIndex: "1",
            width: "22px",
            height: "22px",
            background: "#ffffff",
            borderRadius: "50%",
            right: "-11px",
            boxShadow: "inset 2px 0 0 #0000001A",
          }}
        ></span>
        <thead>
          <tr>
            <td
              style={{
                fontSize: "large",
                fontWeight: "600",
                color: "black",
              }}
            >
              Event Ticket
            </td>
            <td
              style={{
                fontSize: "large",
                fontWeight: "600",
                color: "black",
              }}
            ></td>
          </tr>
        </thead>

        <tbody>
          {Boolean(formikProps.values.eventAmount) && (
            <tr>
              <td>
                <TiTicket style={{ fontSize: "19px", verticalAlign: "top" }} />{" "}
                {1}&nbsp; &#10006; &nbsp;
                {eventObject?.event_name}&nbsp; - &nbsp; ₹
                {formikProps.values.eventAmount}
              </td>
              <td style={{ textAlign: "end" }}>
                ₹{formikProps.values.eventAmount}
              </td>
            </tr>
          )}

          {formikProps.values?.badge?.map((item, index) => {
            return (
              <Fragment>
                {item?.badge_count != 0 && (
                  <tr>
                    <td>
                      <TiTicket
                        style={{
                          fontSize: "19px",
                          verticalAlign: "top",
                        }}
                      />
                      {item?.badge_count}&nbsp; &#10006; &nbsp; {item?.name}{" "}
                      &nbsp;- &nbsp;₹{" "}
                      {item?.badge_count * item?.badge_id_amount || 0}
                    </td>
                    <td style={{ textAlign: "end" }}>
                      ₹ {item?.badge_count * item?.badge_id_amount || 0}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}

          {formikProps.values?.vehicle?.map((item, index) => {
            return (
              <Fragment>
                {formikProps.values.vehicle.length && (
                  <tr>
                    <td>
                      <TiTicket
                        style={{
                          fontSize: "19px",
                          verticalAlign: "top",
                        }}
                      />{" "}
                      1&nbsp; &#10006; &nbsp; {item.vehicle_type} &nbsp;-
                      &nbsp;₹{" "}
                      {
                        vehicleDetails.filter((ite, index) => {
                          return ite.vehicle_type == item?.vehicle_type;
                        })[0]?.amount
                      }
                    </td>

                    <td style={{ textAlign: "end" }}>
                      ₹{" "}
                      {
                        vehicleDetails.filter((ite, index) => {
                          return ite.vehicle_type == item?.vehicle_type;
                        })[0]?.amount
                      }
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}

          <tr>
            <td
              style={{
                fontSize: "large",
                fontWeight: "700",
                color: "black",
              }}
            >
              Total
            </td>

            <td
              style={{
                fontSize: "large",
                fontWeight: "700",
                color: "black",
                textAlign: "end",
              }}
            >
              ₹
              {parseInt(formikProps.values.eventAmount) + (totaBadgeValue || 0)}
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  );
}
