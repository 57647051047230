import { Box } from "@mui/system";
import styled from "styled-components";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";

import geo from "../assests/geo.svg";
import date from "../assests/date.svg";
import locationIcon from "../assests/location.svg";

import China from "../assests/china.svg";
import Time from "../assests/time.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useAxios } from "../../../hooks";
import { useSelector } from "react-redux";
import pages from "../../../constants/pages";
import { IoIosArrowForward } from "react-icons/io";

import Docs from "../assests/docs.svg";
import Chats from "../assests/chats.svg";

import Duration from "../assests/stopwatch.svg";
import Layout from "../assests/layout.svg";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  // padding: 20px;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

const Img = styled.img`
  border-radius: 8px;
`;

export default function EventDetail() {
  const axios = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const queryParams = new URLSearchParams(location.search);

  const eventID = queryParams.get("event_id");

  const [eventDetail, setEventDetail] = useState({});

  useEffect(() => {
    axios({
      url: "admin/events/event-details?event_id=" + eventID,
      method: "GET",
      customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        response?.data?.button_color &&
          sessionStorage.setItem("buttonColor", response.data.button_color);
        response?.data?.background_color &&
          sessionStorage.setItem(
            "buttonBackground",
            response.data.background_color
          );
        setEventDetail(response.data);
      }
    });
  }, []);

  return (
    <OuterBox>
      <OuterPaper>
        <Grid item xs={12} textAlign={"center"}>
          <Img
            width={"100%"}
            height={"200px"}
            src={eventDetail.banner_image}
          ></Img>

          <Typography variant="body1" fontWeight={600} fontSize={"22px"}>
            {eventDetail.event_name}
          </Typography>
        </Grid>
        <Grid
          item
          container
          sx={{
            border: "0.6px solid #0000001A",
            borderRadius: "8px",
            marginTop: "15px",
          }}
        >
          <Grid item container p={"15px 15px 10px 15px"}>
            <Grid item container xs={1}>
              <Img width={"20px"} src={date}></Img>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" fontWeight={400} fontSize={"14px"}>
                Sat, 24 Jan | 10 AM onwards
              </Typography>

              <Typography
                variant="body1"
                fontWeight={400}
                fontSize={"11px"}
                color={"#00000080"}
              >
                Gates open at 9:30 AM
              </Typography>
            </Grid>
          </Grid>

          <Grid item container justifyContent={"center"}>
            <Divider
              sx={{
                background: "#0000000F",
                width: "90.5%",
              }}
            />
          </Grid>

          <Grid item container p={"5px 10px 10px 15px"}>
            <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
              <Img width={"20px"} src={locationIcon}></Img>
            </Grid>
            <Grid item xs={9.5} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" fontWeight={400} fontSize={"14px"}>
                {eventDetail?.address?.full_address}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{
                display: "flex",
                justifyContent: "center",
                background: "#3936EF1A",
                borderRadius: "4px",
                padding: "7px 0px",
              }}
              onClick={() => {
                window.location.replace(eventDetail?.address.map_link);
              }}
            >
              <Img width={"27px"} src={geo}></Img>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container flexDirection={"column"} m={"25px 0px"}>
          <Typography
            variant="h5"
            fontSize={"20px"}
            fontWeight={600}
            mb={"15px"}
          >
            About
          </Typography>
          <Typography variant="body1">{eventDetail.description}</Typography>
        </Grid>

        <Grid item container>
          {eventDetail.duration && (
            <Grid item container>
              <Grid
                item
                xs={1.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "6px",
                  marginRight: "20px",
                  background: "#F6F6F6",
                }}
              >
                <Img src={China} width={"25px"} />
              </Grid>

              <Grid item xs={9}>
                <Typography
                  variant="body1"
                  fontSize={"11px"}
                  color={"#00000080"}
                >
                  Duration
                </Typography>
                <Typography variant="body1" fontSize={"14px"}>
                  {eventDetail.Duration}
                </Typography>
              </Grid>
            </Grid>
          )}

          {eventDetail.layout && (
            <Grid item container m={"17.5px 0px"}>
              <Grid
                item
                xs={1.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "6px",
                  marginRight: "20px",
                  background: "#F6F6F6",
                }}
              >
                <Img src={Layout} width={"25px"} />
              </Grid>

              <Grid item xs={9}>
                <Typography
                  variant="body1"
                  fontSize={"11px"}
                  color={"#00000080"}
                >
                  Layout
                </Typography>
                <Typography variant="body1" fontSize={"14px"}>
                  {eventDetail.layout}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>

        {eventDetail?.gallery?.length !== 0 && (
          <Fragment>
            {" "}
            <Typography variant="h5" fontWeight={600}>
              Gallery
            </Typography>
            <Grid
              item
              container
              justifyContent="center"
              flexDirection={"column"}
              sx={{ overflow: "hidden" }}
            >
              <Grid
                item
                container
                xs={12}
                sx={{
                  alignContent: "center",
                  justifyContent: "space-between",
                  textAlign: { xs: "center", sm: "start" },
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    borderRadius: "12.5px",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    flexDirection: "row",
                    padding: "10px 5px !important",
                  }}
                  xs={12}
                >
                  {eventDetail?.gallery?.map((item, index) => {
                    return (
                      <Grid
                        item
                        container
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Grid item>
                          <img
                            src={item}
                            className="QrCodeImage"
                            width={"130px"}
                            height={"130px"}
                            style={{ margin: "0px 2.5px", borderRadius: "8px" }}
                          />
                        </Grid>
                        <Grid item p={"5px 0px"}>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              fontSize={"14px"}
                              fontWeight={500}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        )}

        {eventDetail?.lineup?.length !== 0 && (
          <Fragment>
            {" "}
            <Typography variant="h5" fontWeight={600}>
              Line up
            </Typography>
            <Grid
              item
              container
              justifyContent="center"
              flexDirection={"column"}
              sx={{ overflow: "hidden" }}
            >
              <Grid
                item
                container
                xs={12}
                sx={{
                  alignContent: "center",
                  justifyContent: "space-between",
                  textAlign: { xs: "center", sm: "start" },
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    borderRadius: "12.5px",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    flexDirection: "row",
                    padding: "10px 5px !important",
                  }}
                  xs={12}
                >
                  {eventDetail?.lineup?.map((item, index) => {
                    return (
                      <Grid
                        item
                        container
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Grid item>
                          <img
                            src={item}
                            className="QrCodeImage"
                            width={"130px"}
                            height={"130px"}
                            style={{ margin: "0px 5px", borderRadius: "8px" }}
                          />
                        </Grid>
                        <Grid item p={"5px 0px"}>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              fontSize={"14px"}
                              fontWeight={500}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        )}

        {eventDetail?.guest?.length !== 0 && (
          <Fragment>
            {" "}
            <Typography variant="h5" fontWeight={600}>
              Artists
            </Typography>
            <Grid
              item
              container
              justifyContent="center"
              flexDirection={"column"}
              sx={{ overflow: "hidden" }}
            >
              <Grid
                item
                container
                xs={12}
                sx={{
                  alignContent: "center",
                  justifyContent: "space-between",
                  textAlign: { xs: "center", sm: "start" },
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    borderRadius: "12.5px",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    flexDirection: "row",
                    padding: "10px 5px !important",
                  }}
                  xs={12}
                >
                  {eventDetail?.guest?.map((item, index) => {
                    return (
                      <Grid
                        item
                        container
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Grid item>
                          <img
                            src={item}
                            className="QrCodeImage"
                            width={"130px"}
                            height={"130px"}
                            style={{ margin: "0px 20px", borderRadius: "100%" }}
                          />
                        </Grid>
                        <Grid item p={"5px 0px"}>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              fontSize={"14px"}
                              fontWeight={500}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        )}

        {eventDetail?.sponsorships?.length !== 0 && (
          <Fragment>
            {" "}
            <Typography variant="h5" fontWeight={600}>
              Sponsor Ships
            </Typography>
            <Grid
              item
              container
              justifyContent="center"
              flexDirection={"column"}
              sx={{ overflow: "hidden" }}
            >
              <Grid
                item
                container
                xs={12}
                sx={{
                  alignContent: "center",
                  justifyContent: "space-between",
                  textAlign: { xs: "center", sm: "start" },
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    borderRadius: "12.5px",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    flexDirection: "row",
                    padding: "10px 5px !important",
                  }}
                  xs={12}
                >
                  {eventDetail?.sponsorships?.map((item, index) => {
                    return (
                      <Grid
                        item
                        container
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Grid item>
                          <img
                            src={item}
                            className="QrCodeImage"
                            width={"130px"}
                            height={"130px"}
                            style={{ margin: "0px 20px", borderRadius: "100%" }}
                          />
                        </Grid>
                        <Grid item p={"5px 0px"}>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              fontSize={"14px"}
                              fontWeight={500}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        )}

        <Typography variant="h5" fontWeight={600}>
          Venue
        </Typography>

        <Grid
          item
          container
          marginBottom={"20px"}
          sx={{
            border: "0.6px solid #0000001A",
            borderRadius: "8px",
            marginTop: "15px",
          }}
        >
          <Grid item container p={"15px 15px 10px 15px"}>
            <Grid item container xs={1} alignItems={"baseline"}>
              <Img width={"20px"} src={locationIcon}></Img>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" fontWeight={400} fontSize={"14px"}>
                {eventDetail?.address?.full_address}
              </Typography>

              <Typography
                variant="body1"
                fontWeight={400}
                fontSize={"11px"}
                color={"#00000080"}
              >
                {eventDetail?.address?.pincode}
              </Typography>

              <Typography
                variant="body1"
                fontWeight={600}
                fontSize={"14px"}
                color={"#3936EF"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                Get Directions <IoIosArrowForward fontSize={"20px"} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Typography variant="h5" fontWeight={600}>
          More
        </Typography>

        <Grid
          item
          container
          marginBottom={"90px"}
          sx={{
            border: "0.6px solid #0000001A",
            borderRadius: "8px",
            marginTop: "15px",
          }}
        >
          <Grid item container p={"15px 15px 10px 15px"}>
            <Grid item container p={"5px 10px 10px 15px"}>
              <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
                <img width={"20px"} src={Docs}></img>
              </Grid>
              <Grid
                item
                xs={9.5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body1" fontWeight={400} fontSize={"14px"}>
                  Terms & Conditions
                </Typography>
              </Grid>

              <Grid
                item
                xs={1.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",

                  borderRadius: "4px",
                  padding: "7px 0px",
                }}
                onClick={() => {
                  // window.location.replace(eventDetail?.address.map_link);
                }}
              >
                <IoIosArrowForward fontSize={"20px"} color="#3936EF" />
              </Grid>
            </Grid>

            <Grid item container justifyContent={"center"}>
              <Divider
                sx={{
                  background: "#0000000F",
                  width: "90.5%",
                }}
              />
            </Grid>

            <Grid item container p={"5px 10px 10px 15px"}>
              <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
                <img width={"20px"} src={Chats}></img>
              </Grid>
              <Grid
                item
                xs={9.5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body1" fontWeight={400} fontSize={"14px"}>
                  Frequently Asked Questions{" "}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",

                  borderRadius: "4px",
                  padding: "7px 0px",
                }}
                onClick={() => {
                  // window.location.replace(eventDetail?.address.map_link);
                }}
              >
                <IoIosArrowForward fontSize={"20px"} color="#3936EF" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sx={{
            display: "flex",
            background: "white",
            position: "fixed",
            width: "100%",
            bottom: "0",
            left: "0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            padding: "7.5px 20px",
          }}
        >
          <Grid item xs={8}>
            <Typography variant="body1" fontWeight={600}>
              ₹{eventDetail.ticket_cost} onwards
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                sessionStorage.setItem("eventId", eventID);
                navigate(
                  pages.EventBooking.route +
                    "/" +
                    eventID +
                    "/" +
                    visitorData.ID
                );
              }}
            >
              Book
            </Button>
          </Grid>
        </Grid>
      </OuterPaper>
    </OuterBox>
  );
}
