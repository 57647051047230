import { useFormik } from "formik";
import { Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";

import {
  LoadingButton,
  SubheaderSection,
  MandatoryNote,
} from "./../../../components";
import Form from "./form components/Form";
import Form1 from "./form components/Form1";
import pages from "../../../constants/pages";
import { useAlert, useAxios } from "./../../../hooks/";
import { formData } from "./form components/appointmentBookingObject";
import { validationSchema } from "./form components/appointmentBookingObject";

const MyComponent = () => {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [loading, setLoading] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const onSubmit = async (values) => {
    let ObjectValue = {
      reference_visitor_id: sessionStorage.getItem("visitorId"),
    };

    let formData = [];
    let appointmentDate = new Date(values.date);

    for (let i = 0; i < values.memberCount; i++) {
      formData[i] = {
        state_id: values[`state${i}`],
        district_id: values[`district${i}`],
        name: values[`name${i}`],
        gender: values[`gender${i}`],
        phone_number: "91" + values[`mobile${i}`],
        email_id: values[`email${i}`],
        ...(i === 0 && { designation: values.designation || "" }),
        ...(i === 0 && { organization_name: values.organization || "" }),
        department_id: values[`department`],
        purpose: values?.PurposeVisit,
        sub_department_id: values[`subDepartment`],
        slot_id: values["time"],
        appointment_date: appointmentDate.getTime() / 1000,
        officer_id: values.officer,
      };
    }
    ObjectValue.visitors = formData;
    try {
      setLoading(true);

      const response = await axios({
        url: "/appointment/appointment/book-appointment",
        method: "POST",
        data: ObjectValue,
      });
      if (response.status) {
        setLoading(false);
        dispatch(
          alert({
            type: "success",
            message: response.message.displayMessage,
          })
        );
        sessionStorage.setItem("appointmentID", response.data.appointment_id);

        navigate(
          `${pages.Verification?.route}?visitor_id=${response.data.visitor_id}&appointment_id=${response.data.appointment_id}&officer_id=${response.data.officer_id}`,
          {
            state: {
              details: response.data,
            },
          }
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in Appointment booking", error);
    }
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit,
  });

  const [premises, setPremises] = useState([]);
  const [officials, setOfficials] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);
  const [memberCount, setMemberCount] = useState(formik.values.memberCount);

  useEffect(() => {
    axios({
      url: "appointment/appointment/get-premises",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setPremises(response.data);
      }
    });

    formik.setFieldValue("mobile0", sessionStorage.getItem("mobileNumber"));

    formik.setFieldValue(
      "name0",
      visitorData.firstName + " " + visitorData.lastName
    );
  }, []);

  function handleAddMember() {
    setMemberCount(memberCount + 1);
    formik.setFieldValue("memberCount", memberCount + 1);
  }

  useEffect(() => {
    const newInviteList = Array(memberCount)
      .fill("0")
      .map((item, index) => {
        const formikKey = `name${index}`;
        return formik.values[formikKey] || "";
      });

    if (JSON.stringify(newInviteList) !== JSON.stringify(inviteList)) {
      setInviteList(newInviteList);
    }
  }, [formik.values, memberCount]);

  return (
    <Fragment>
      <Grid container padding={5}>
        <SubheaderSection
          textAlign="left"
          title="Book Appointment"
          content="Please provide the following details to book your appointment"
        />

        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Form
            formik={formik}
            premises={premises}
            departments={departments}
            setDepartments={setDepartments}
            officials={officials}
            subDepartments={subDepartments}
            setSubDepartments={setSubDepartments}
          />

          <Box sx={{ display: "flex", alignItems: "left", mt: "16px" }}>
            <SubheaderSection
              textAlign="left"
              title="Personal Details"
              variant="h3"
            />
          </Box>

          <Form1
            formik={formik}
            memberCount={memberCount}
            setMemberCount={setMemberCount}
          />

          <LoadingButton
            backgroundColor="rgb(215 156 49 / 75%)"
            onClick={handleAddMember}
            disabled={memberCount === 5}
          >
            Add More Member
          </LoadingButton>

          <MandatoryNote />

          <LoadingButton
            loading={loading}
            onClick={formik.handleSubmit}
            disabled={uploadLoading}
          >
            Request Appointment
          </LoadingButton>
        </form>
      </Grid>
    </Fragment>
  );
};

export default MyComponent;
