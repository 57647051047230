import * as React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import { CheckCircle } from "@mui/icons-material";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import pages from "../../constants/pages";
import { useSelector } from "react-redux";

const steps = ["Person Detail", "Face Verification", "Docs Verification"];

const StageConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#096929",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#096929",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StageStepRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#096929",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#096929",
    zIndex: 1,
    fontSize: 22,
  },
  "& .QontoStepIcon-circle": {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "#096929",
  },
}));

function StageIcon(props) {
  const { active, completed, className } = props;

  return (
    <StageStepRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircle className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </StageStepRoot>
  );
}

export default function StageStepper({ userData }) {
  const navigate = useNavigate();
  const { visitorData } = useSelector((state) => state.memberDetails);

  let stage = 0;

  if (userData.is_doc_verified) {
    stage = 3;
  } else if (userData.is_verified) {
    stage = 2;
  } else if (userData.first_name !== "") {
    stage = 1;
  }

  function handleSteps(stage) {
    if (stage == 0) {
      //   navigate(
      //     `${pages.Verification?.route}?visitor_id=${
      //       visitorData.ID
      //     }&appointment_id=${-1}&officer_id=${-1}`
      //   );
    } else if (stage == 1) {
      navigate(
        `${pages.checkLiveliness?.route}?visitor_id=${
          userData._id
        }&appointment_id=${-1}&officer_id=${-1}&doc_face=false`
      );
    } else if (stage == 2) {
      navigate(
        `${pages.Verification?.route}?visitor_id=${
          userData._id
        }&appointment_id=${-1}&officer_id=${-1}&doc_verify=true`
      );
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        margin: "15px 0px 0px 0px",
        // border: "1px solid black",
        padding: "15px 0px",
        borderRadius: "8px",
      }}
    >
      <Stepper
        activeStep={stage}
        connector={<StageConnector />}
        alternativeLabel
      >
        {steps.map((label, index) => (
          <Step key={label}>
            {console.log(index, stage, "index < stage", label)}
            <StepLabel StepIconComponent={StageIcon}>{label}</StepLabel>
            {index == stage && (
              <Button
                variant="contained"
                fullWidth
                sx={{ marginTop: "5px", marginBottom: "5px" }}
                onClick={() => handleSteps(stage)}
              >
                Submit
              </Button>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
