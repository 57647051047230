import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { TextField } from "../../components";
import { Fragment, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { useAxios } from "../../hooks";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
}));

export default function Filter({
  dailogOpen,
  setDailogOpen,
  dependentID,
  setTransaction,
  setFilterTransaction,
}) {
  const axios = useAxios();
  const classes = useStyles();

  const { visitorData } = useSelector((state) => state.memberDetails);

  const [formData, setFormData] = useState({
    type: "",
    startDate: "",
    endDate: "",
  });

  function handleClose() {
    setDailogOpen(false);
  }

  function handleClearHistory() {
    axios({
      url:
        "/admin/profile/get-user-bookings?" +
        (dependentID === "phone_number"
          ? "phone_number=" + visitorData.phoneNumber
          : "visitor_id=" + dependentID),
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTransaction(response.data);
        setFilterTransaction(response.data);
      }
    });

    setFormData({ type: "", startDate: "", endDate: "" });
    handleClose();
  }
  return (
    <Dialog
      open={dailogOpen}
      classes={{ paper: classes.paper }}
      onClose={handleClose}
    >
      <DialogTitle style={{ paddingBottom: "4px" }}>Fitler List</DialogTitle>

      <Formik
        initialValues={formData}
        enableReinitialize
        onSubmit={(formData) => {
          axios({
            url:
              "/admin/profile/get-user-bookings?" +
              (dependentID === "phone_number"
                ? "phone_number=" + visitorData.phoneNumber
                : "visitor_id=" + dependentID) +
              (formData.type !== "" ? "&booking_type=" + formData.type : "") +
              (formData.startDate !== ""
                ? "&start_date=" + new Date(formData.startDate).getTime()
                : "") +
              (formData.endDate !== ""
                ? "&end_date=" + new Date(formData.endDate).getTime()
                : ""),
            method: "GET",
            disableRedirect: true,
          }).then((response) => {
            if (response.status) {
              setTransaction(response.data);
              setFilterTransaction(response.data);
            }
            setFormData({
              type: formData.type,
              startDate: formData.startDate,
              endDate: formData.endDate,
            });
            handleClose();
          });
        }}
      >
        {(formikProps) => {
          return (
            <Fragment>
              <DialogTitle style={{ paddingBottom: "4px" }}>
                Select Type
              </DialogTitle>

              <Grid container p={"0px 15px"}>
                <TextField
                  select
                  name="type"
                  label="Selct Type"
                  size="small"
                  formikProps={formikProps}
                >
                  <MenuItem value={1}>Events</MenuItem>
                  <MenuItem value={2}>Appointments</MenuItem>
                </TextField>
              </Grid>

              <Grid container p={"0px 15px"} marginBottom={"10px"}>
                <DialogTitle
                  style={{ padding: "20px 0px 0px 0px", textAlign: "start" }}
                >
                  Select Date
                </DialogTitle>

                <Grid item xs={12}>
                  <DatePicker
                    label={
                      <>
                        Select Start Date
                        <span
                          style={{
                            color: "red",
                            float: "right",
                            paddingLeft: "5px",
                          }}
                        >
                          *
                        </span>
                      </>
                    }
                    autoComplete="off"
                    placeholder="Select Start Date"
                    value={formikProps.values.startDate}
                    onBlur={formikProps.handleBlur}
                    error={
                      formikProps.touched.date &&
                      Boolean(formikProps.errors.startDate)
                    }
                    onChange={(newValue) =>
                      formikProps.setFieldValue("startDate", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={"startDate"}
                        onBlur={formikProps.handleBlur}
                        error={
                          formikProps.touched.startDate &&
                          Boolean(formikProps.errors.startDate)
                        }
                        value={formikProps.values.startDate}
                      />
                    )}
                    inputFormat="dd/MM/yyyy" // Set the desired date format here
                    maxDate={new Date()}
                  />
                </Grid>

                <Grid item xs={12} marginTop={"5px"}>
                  <DatePicker
                    label={
                      <>
                        Select End Date
                        <span
                          style={{
                            color: "red",
                            float: "right",
                            paddingLeft: "5px",
                          }}
                        >
                          *
                        </span>
                      </>
                    }
                    autoComplete="off"
                    placeholder="Select End Date"
                    value={formikProps.values.endDate}
                    onBlur={formikProps.handleBlur}
                    error={
                      formikProps.touched.date &&
                      Boolean(formikProps.errors.endDate)
                    }
                    onChange={(newValue) =>
                      formikProps.setFieldValue("endDate", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={"endDate"}
                        onBlur={formikProps.handleBlur}
                        error={
                          formikProps.touched.endDate &&
                          Boolean(formikProps.errors.endDate)
                        }
                        value={formikProps.values.endDate}
                      />
                    )}
                    inputFormat="dd/MM/yyyy" // Set the desired date format here
                    maxDate={new Date()}
                  />
                </Grid>
              </Grid>

              <DialogActions style={{ padding: "0px 24px 16px" }}>
                <Button
                  onClick={() => {
                    formikProps.handleSubmit();

                    handleClose();
                  }}
                  disabled={!formikProps.dirty}
                  variant="contained"
                  size="small"
                >
                  Submit
                </Button>

                <Button
                  onClick={handleClearHistory}
                  variant="contained"
                  sx={{ background: "white", color: "black" }}
                  size="small"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Fragment>
          );
        }}
      </Formik>
    </Dialog>
  );
}
