import { Button, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import TextField from "./../../components/TextField";
import FileUpload from "./../../components/FileUpload";
import SubheaderSection from "./../../components/SubheaderSection";
import { useAxios } from "../../hooks";
import { get } from "lodash";
import { convertDate } from "../../utils";
import { DatePicker } from "@mui/x-date-pickers";
import DateTimePicker from "../../components/DateTimePicker";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import pages from ".././../constants/pages";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  // padding: 20px;
  min-height: 60vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

export default function AddHotelBooking() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [formData, setFormData] = useState({
    ota: "",
    bookingNumber: "",
    otaPDF: "",
    checkOutDateTime: "",
    checkInDateTime: "",
    hotelID: "",
  });
  const [loading, setLoading] = useState(false);
  const [pdfLink, setPDFLink] = useState(null);
  const [pdfName, setPDFName] = useState(null);
  const [pdfData, setPDFData] = useState(null);

  useEffect(() => {
    if (pdfLink) {
      setLoading(true);
      const Form = new FormData();

      Form.append("booking_pdf", pdfLink);
      axios({
        url: "hotel/checkins/ocr",
        method: "POST",
        data: Form,
        disableError: true,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setPDFData(response.data);
          setFormData((prev) => ({
            ...prev,
            otaPDF: pdfName,
            ota: response.data?.hotel_name,
            bookingNumber: response.data?.booking_id,
            checkOutDateTime: convertDate(response.data?.checkin_datetime),
            checkInDateTime: convertDate(response.data?.checkout_datetime),
            hotelID: response.data?.hotel?.hotel_id,
          }));
        }

        setLoading(false);
      });
    }
  }, [pdfLink]);

  return (
    <>
      <OuterBox>
        <OuterPaper>
          <Grid item xs={12}>
            <SubheaderSection
              textAlign="start"
              title="Add Hotel Booking"
              content="Enter your OTA "
            />
          </Grid>

          <Formik
            initialValues={formData}
            enableReinitialize
            onSubmit={(formData, { setSubmitting }) => {
              axios({
                url: "hotel/checkins/entity-locker/pdf/" + visitorData.ID,
                method: "POST",
                data: {
                  booking_id: formData?.bookingNumber,
                  guests_count: pdfData?.guests_count,
                  checkin_datetime:
                    new Date(formData?.checkInDateTime).getTime() / 1000,
                  checkout_datetime:
                    new Date(formData?.checkOutDateTime).getTime() / 1000,
                  hotel_id: formData?.hotelID,
                },
              }).then((response) => {
                if (response.status) {
                  navigate(pages.HotelBookingPage.route);
                }
              });
            }}
          >
            {(formikProps) => {
              if (formikProps.values.otaPDF) {
                if (formikProps.values.otaPDF) {
                  setPDFLink(formikProps.values.otaPDF);
                }
              }
              return (
                <Fragment>
                  <Grid item xs={12} mb={"25px"}>
                    <FileUpload
                      name="otaPDF"
                      label="Upload OTA PDF"
                      formikProps={formikProps}
                      onChange={({ target }) => {
                        const file = get(target, "files[0]", "");
                        formikProps.setFieldValue("otaPDF", file.name);

                        setPDFName(file.name);

                        setPDFLink(file);
                      }}
                      loading={loading}
                      allowedExtension=".pdf,.png,.jpg,.jpeg"
                      folderPath={
                        "mpsedc/digipass/events" + new Date().getTime() + "_"
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="ota"
                      label="OTA"
                      formikProps={formikProps}
                      disabled={pdfData === null}
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} m={"25px 0px"}>
                    <TextField
                      name="hotelID"
                      label="Hotel ID"
                      formikProps={formikProps}
                      disabled={pdfData === null}
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} m={"25px 0px"}>
                    <TextField
                      name="bookingNumber"
                      label="Booking Number"
                      formikProps={formikProps}
                      disabled={pdfData === null}
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} m={"25px 0px"}>
                    <DateTimePicker
                      name="checkInDateTime"
                      label={"Check-In Date"}
                      formikProps={formikProps}
                      // minTime={new Date().getTime()}
                      editState={!(pdfData === null)}
                    />
                  </Grid>

                  <Grid item xs={12} m={"25px 0px"}>
                    <DateTimePicker
                      name="checkOutDateTime"
                      label={"Check-Out Date"}
                      formikProps={formikProps}
                      editState={!(pdfData === null)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={formikProps.handleSubmit}
                      disabled={pdfLink === null}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Fragment>
              );
            }}
          </Formik>
        </OuterPaper>
      </OuterBox>
    </>
  );
}
