import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { useOutlet } from "react-router-dom";

const Wrapper = styled(Box)`
  flex-grow: 1;
  z-index: -0;
`;

export default function MainContent() {
  const element = useOutlet();
  return <Wrapper style={{ marginTop: "56px" }}>{element}</Wrapper>;
}
