//import { create } from "jss";
import React, { useContext, useEffect } from "react";
//import jssPreset from "@mui/styles/jssPreset";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Alert, Snackbar, Slide } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components/macro";
import StylesProvider from "@mui/styles/StylesProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createBrowserHistory } from "history";

import "./css/style.css";
import createTheme from "./theme";
import { useAxios } from "./hooks";
import Router from "./router/Router";
import { closeAlert } from "./redux/slices/alertSlice";
import { ThemeContext } from "./components/ThemeProvider";
import { setAccessToken, setDisplayName } from "./redux/slices/memberSlice";
import PushNotification from "./pages/Push Notification/PushNotification";
import { useLocation, useNavigate } from "react-router-dom";
import pages from "./constants/pages";

// const history = createBrowserHistory({ basename: "/booking" });

function App() {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const sessionStorageToken = sessionStorage.getItem("authToken");
  const alert = useSelector((state) => state.alertDetails);
  const { visitorData, authToken } = useSelector(
    (state) => state.memberDetails
  );

  const { theme } = useContext(ThemeContext);

  const queryParams = new URLSearchParams(location.search);

  const popup = queryParams.get("popup");
  const is_login = ["/sign-in"].filter((item) =>
    location.pathname.includes(item)
  );

  PushNotification();

  useEffect(() => {
    console.log(
      !sessionStorageToken,
      "IN",
      visitorData.ID,
      " authToken",
      sessionStorageToken
    );
    if (!sessionStorageToken && !popup && !(is_login.length != 0)) {
      navigate(pages.appointment.route + "login");
    }
  }, []);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log("Service Worker registered:", registration);
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }

    dispatch(setAccessToken(sessionStorage.getItem("authToken")));
    dispatch(setDisplayName(sessionStorage.getItem("displayName")));
  }, []);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      let data = [];
      let visitorIds = [];
      let localStorageData = localStorage;

      visitorIds = visitorData?.dependentList?.map((itm) => itm._id) || [];

      visitorData.ID && visitorIds?.push(visitorData.ID);

      visitorIds?.forEach((itm) => {
        let faceValue = "";
        let docs = {};

        Object.keys(localStorage).forEach((item) => {
          if (item.includes(itm) && !item.includes("userPayload")) {
            faceValue = localStorageData.getItem(item);
          }

          if (item.includes(itm) && item.includes("userPayload")) {
            docs = JSON.parse(localStorageData.getItem(item));
          }
        });

        data.push({
          visitor_id: itm,
          face_data: faceValue ? faceValue : "",
          doc_data: docs,
        });

        localStorage.setItem([window.btoa(itm)], faceValue ? true : false);
      });

      if (data.length !== 0) {
        axios({
          url: `admin/profile/check-visitor-verification`,
          method: "POST",
          data: data,
          disableError: true,
          disableRedirect: true,
          customAuthToken: sessionStorageToken,
        });
      }
    }, 10);

    return () => clearTimeout(timeoutId);
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | Verismart.ai" defaultTitle="Verismart AI" />

      <StylesProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ThemeProvider theme={createTheme(theme)}>
                <Snackbar
                  open={alert.state}
                  autoHideDuration={4000}
                  onClose={handleAlertClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }} // Center align at the top
                  TransitionComponent={(props) => (
                    <Slide
                      {...props}
                      in={alert.state}
                      direction="right"
                      unmountOnExit
                    />
                  )}
                  style={{
                    top: "50px", // Set the desired distance from the top
                    left: "50%", // Horizontally align at 50% from left
                    transform: "translateX(-50%)", // Center align horizontally
                  }}
                >
                  <Alert
                    variant="outlined"
                    severity={alert.type}
                    onClose={handleAlertClose}
                    sx={{
                      minWidth: "350px",
                      background: "white",
                      borderRadius: "10px",
                    }}
                  >
                    {alert.message}
                  </Alert>
                </Snackbar>

                <Router />
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </HelmetProvider>
  );

  function handleAlertClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeAlert());
  }
}

export default App;
